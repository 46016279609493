import React, { Suspense, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { setInternetSearch } from './redux/slices/common.slice'
import { useAppDispatch } from './redux/store'
import router from './router'
import authService from './services/auth.service'
import { useSelector } from 'react-redux'
import { selectUserDetails, setUserDetails } from './redux/slices/user.slice'
import { useMsal } from '@azure/msal-react'

function App() {
  const dispatch = useAppDispatch()
  const userData = useSelector(selectUserDetails)
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()

  useEffect(() => {
    dispatch(setInternetSearch(localStorage.getItem('internetSearch') === 'true'))
    if (activeAccount?.idToken) {
      authService.getUserDetails().then((response) => {
        localStorage.setItem('showExperimentalSectionFlag', response.data.SHOW_EXPERIMENTAL ? '1' : '0')
        dispatch(setUserDetails(response.data))
      })
    }
  }, [activeAccount?.idToken])

  if (authService.isUserLoggedIn() && !userData?.USER_ID) return <div>Loading...</div>

  return (
    <Suspense>
      <RouterProvider router={router} />
      <ToastContainer />
    </Suspense>
  )
}

export default App
