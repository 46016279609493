export interface LLMOption {
  value: string
  label: string
  description: string
}

export class LLMModelService {
  // Returns the selected model from localStorage, or a default value.
  getSelectedModel(): string {
    let storedModel = localStorage.getItem('llm-model')
    if (!storedModel) {
      const options = this.getOptions()
      if (options.length > 0) {
        storedModel = options[0].value
        localStorage.setItem('llm-model', storedModel)
      } else {
        // Fallback in case there are no options at all.
        storedModel = 'gpt-4o-mini'
        localStorage.setItem('llm-model', storedModel)
      }
    }
    return storedModel
  }

  // Persists the selected model in localStorage.
  setModel(model: string): void {
    localStorage.setItem('llm-model', model)
  }

  // Returns the stored options list. If none exist, returns the default options.
  getOptions(): LLMOption[] {
    const storedOptions = localStorage.getItem('llm-options')
    if (storedOptions) {
      try {
        return JSON.parse(storedOptions) as LLMOption[]
      } catch (error) {
        console.error('Failed to parse stored LLM options, using default options.', error)
      }
    }
    console.error('Returning getDefaultOptions()')
    return this.getDefaultOptions()
  }

  /**
   * Persists a new options list in localStorage.
   *
   * Accepts either:
   * - An array of LLMOption objects, or
   * - A string formatted as a JSON array of strings, e.g. "['gpt-4o', 'gpt-4o-mini']".
   *
   * In the latter case, the method converts each string (model name) into an LLMOption object
   * using default values for label and description if no default exists.
   */
  setOptions(options: LLMOption[] | string[]): void {
    let newOptions: LLMOption[]

    if (Array.isArray(options)) {
      if (options.length > 0 && typeof options[0] === 'string') {
        // It's an array of strings.
        const defaultOptions = this.getDefaultOptions()
        const defaultOptionsMap = new Map(defaultOptions.map((option) => [option.value, option]))
        newOptions = (options as string[]).map(
          (value: string) =>
            defaultOptionsMap.get(value) || { value, label: value, description: '' }
        )
      } else {
        // Assume it's an array of LLMOption objects.
        newOptions = options as LLMOption[]
      }
    } else {
      // Fallback to default options if for some reason it's not an array.
      newOptions = this.getDefaultOptions()
    }

    localStorage.setItem('llm-options', JSON.stringify(newOptions))
  }

  // Persists a new options list from Agent Card in localStorage.
  // The input array contains only the values like ['gpt-4o', 'gpt-4o-mini'].
  // This method converts them into full LLMOption objects.
  setOptionsFromAgent(options: string[]): void {
    // Retrieve the default options to use as a lookup.
    const defaultOptions = this.getDefaultOptions()
    const defaultOptionsMap = new Map(defaultOptions.map((option) => [option.value, option]))

    // Map each provided value to an LLMOption.
    const newOptions: LLMOption[] = options.map(
      (value) => defaultOptionsMap.get(value) || { value, label: value, description: '' }
    )

    localStorage.setItem('llm-options', JSON.stringify(newOptions))
  }

  // Provides the default options.
  getDefaultOptions(): LLMOption[] {
    return [
      {
        value: 'gpt-4o-mini',
        label: 'Efficient',
        description: 'Faster for daily tasks'
      },
      {
        value: 'gpt-4o',
        label: 'Smart',
        description: 'Advanced reasoning and broad tasks'
      }
    ]
  }
}

const llmModelService = new LLMModelService()
export default llmModelService
